import { useMutation } from '@apollo/client';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import LaunchIcon from '@mui/icons-material/Launch';

import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Chip from '@mui/material/Chip';

import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Settings } from '../../Settings';

import { Container } from '@mui/material';



import './config/Helpdesk.css';
import UploadFileButton from './config/UploadFileButton';
import { TicketState } from './config/config';
import { QUERIES, UPDATE_HELPDESK_TICKET, CREATE_TICKET, UPLOAD_SCREENSHOT } from './config/queries'
import HelpdeskModal from './HelpdeskModal';

import { useTheme } from '@mui/material/styles';




const UserHelpdesk = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const initialState = {
        title: "",
        urgency: "",
        description: "",
        screenshot: null
    }
    const [ticket, setTicket] = useState(initialState);

    const [ticketModal, setTicketModal] = useState({
        title: "",
        show: false
    });

    // const { data: categories, loading: categoriesLoading, error: categoriesError } = useQuery(QUERIES.HELPDESK_CATRGORIES)
    const { data, loading, error, refetch } = useQuery(QUERIES.HELPDESK_TICKETS_BY_USER)
    const [getTicket, { data: ticketData, loading: ticketLoading, error: ticketError }] = useLazyQuery(QUERIES.HELPDESK_TICKET);
    const [createTicket, { data: createData, loading: createLoading }] = useMutation(CREATE_TICKET)
    const [recordErrors, setRecordErrors] = useState({ show: false, errors: [] })


    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    )


    const columns = [
        {
            field: "id", headerName: "ID", width: 30,
            valueFormatter: (params) =>
                `#${params.value}`,

        },
        {

            field: "urgency", headerName: "Urgency",
            renderCell: (params) => (
                <Typography variant="body1" color={params.row.urgency === "Low" ? theme.palette.low.main :
                    params.row.urgency === "Medium" ? theme.palette.medium.main :
                        params.row.urgency === "High" ? theme.palette.high.main :
                            theme.palette.critical.main}>
                    {params.row.urgency}
                </Typography>
            )
        },
        { field: "title", sortable: false, flex: 1, headerName: "Title" },
        {
            field: "state",
            width: 150,
            headerName: "Status",
            renderCell: (params) => (
                <TicketState state={params.row.state} />
            )
        },

        {
            field: "openDatetime",
            flex: 1,
            headerName: "Open Date",
            type: "date",
            valueFormatter: (params) =>
                params?.value ?
                    moment(params.value).format('DD/MM/YYYY') : "",

        },
        {
            field: "closeDatetime",
            flex: 1,
            headerName: "Closed Date",
            type: "date",
            valueFormatter: (params) =>
                params?.value ?
                    moment(params.value).format('DD/MM/YYYY') : "Not closed yet",

        },
        { field: "technician", flex: 1, headerName: "Technician", valueGetter: (params) => params.row.technician ? params.row.technician.firstName : "Not assigned yet" },
        {
            field: "actions", sortable: false, headerName: "", renderCell: (params) => (
                <LaunchIcon
                    onClick={() => handleShowTicket(params.row.id)}
                    style={{ cursor: 'pointer' }}
                    sx={{ color: theme.palette.primary.main }}
                />
            )
        }
    ];

    const handleFileChange = (event) => {
        setTicket({ ...ticket, screenshot: event.target.files[0] })
        if (event.target.files[0].type !== "image/png" && event.target.files[0].type !== "image/jpeg") {
            // console.log(event.target.files[0].type)
        }
    };

    const handleFormInput = (field, e) => {
        setTicket({ ...ticket, [field]: e })
    }

    const handleFormSubmit = async () => {
        let errors = []
        let show = false

        if (ticket.title === "") {
            errors.push("Title is required");
            show = true;
        }
        if (ticket.urgency === "") {
            errors.push("Urgency is required");
            show = true;
        }
        if (ticket.description === "") {
            errors.push("Description is required");
            show = true;
        }

        if (ticket.screenshot) {
            if (ticket.screenshot.type !== "image/png" && ticket.screenshot.type !== "image/jpeg") {
                errors.push("Screenshot must be an image")
                show = true
            }
            if (ticket.screenshot.size > 4000000) {
                errors.push("Screenshot must be less than 4MB")
                show = true
            }
        }

        // if errors, show them and return before creating the ticket
        if (show) {
            setRecordErrors({ show: true, errors: errors })
            enqueueSnackbar(`Please fix the errors before submitting`, { variant: "error", });
            return
        }

        await createTicket({
            variables: {
                ...ticket
            }
        })
        setTicket(initialState)
        refetch()
        enqueueSnackbar(`Ticket successfully created!`, { variant: "success", });
    }

    const handleShowTicket = (ticketId) => {
        getTicket({ variables: { ticketId: ticketId } });
        setTicketModal({
            ...ticketModal,
            show: true,
        })
    }
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    // this selector is used to apply styles to all direct children of the parent element that are not `style` elements.

                    '& > :not(style)': {
                        margin: 'auto',
                        width: '60%',
                        p: 3,
                        mt: 5,
                        "@media (max-width: 900px)": {
                            width: "100%",
                        },
                    },
                }}
            >
                <Paper>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            '@media (max-width: 900px)': {
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Grid item md={12} sx={{ mb: 2 }}>
                            <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>New Ticket</Typography>
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="urgency"
                                select
                                label="Urgency"
                                fullWidth
                                variant="outlined"
                                value={ticket.urgency}
                                margin={'dense'}
                                onChange={(e) => handleFormInput('urgency', e.target.value)}
                            >
                                <MenuItem value="Low" sx={{ color: theme.palette.low.main }}>Low</MenuItem>
                                <MenuItem value="Medium" sx={{ color: theme.palette.medium.main }}>Medium</MenuItem>
                                <MenuItem value="High" sx={{ color: theme.palette.high.main }}>High</MenuItem>
                                <MenuItem value="Critical" sx={{ color: theme.palette.critical.main }}>Critical</MenuItem>
                            </TextField>
                        </Grid>
                        {/* <Grid item md={6}>
                            <TextField
                                id="category"
                                select
                                label="Category"
                                fullWidth
                                variant="outlined"
                                value={ticket.category}
                                margin={'dense'}
                                onChange={(e) => handleFormInput('category', e.target.value)}
                            >
                                {categories.helpdeskCategories.map((category) => (
                                    <MenuItem key={category.id} value={category.id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid> */}
                        <Grid item md={12}>
                            <TextField
                                id="title"
                                label="Title"
                                fullWidth
                                variant="outlined"
                                value={ticket.title}
                                margin={'dense'}
                                onChange={(e) => handleFormInput('title', e.target.value)}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                id="description"
                                label="Description"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                value={ticket.description}
                                margin={'dense'}
                                onChange={(e) => handleFormInput('description', e.target.value)}
                            />
                        </Grid>

                        {ticket?.screenshot?.name && (
                            <Grid item md={12}>
                                <Box sx={{ mt: 2 }}>
                                    <img src={URL.createObjectURL(ticket.screenshot)} style={{ height: 150, width: "auto" }} />
                                </Box>
                            </Grid>
                        )}
                        <Grid item md={12} sx={{ mt: 1 }}>
                            <UploadFileButton
                                size="small"
                                text="screenshot"
                                color="primary"
                                onChange={handleFileChange}
                            />
                        </Grid>
                        {recordErrors.show &&
                            <Grid item md={12}>
                                <Box sx={{ mt: 3 }}>
                                    {recordErrors.errors.map((error) => (
                                        <Alert sx={{ m: 1 }} severity="error">{error}</Alert>
                                    ))}
                                </Box>
                            </Grid>
                        }
                        <Grid item md={12} sx={{
                            textAlign: 'right',
                            mt: 2,
                        }}>

                            <Button variant="contained" color="primary" onClick={handleFormSubmit} >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </Paper>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    // this selector is used to apply styles to all direct children of the parent element that are not `style` elements.
                    '& > :not(style)': {
                        margin: 'auto',
                        width: '100%',
                        p: 3,
                        mt: 5,
                    },
                }}
            >
                <Paper>

                    <Grid
                        container
                        spacing={1}
                        sx={{
                            '@media (max-width: 900px)': {
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Grid item md={12}>
                            <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>My Tickets</Typography>
                        </Grid>
                        <Grid item md={12} sx={{ mb: 2 }}>
                            {data.helpdeskTicketsByUser.length === 0 ?
                                <Typography variant="body1" color="primary" sx={{ fontWeight: 'bold', textAlign: 'center' }}>No tickets found</Typography> :

                                <DataGrid
                                    rows={data.helpdeskTicketsByUser}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 20,
                                            },
                                        },
                                        sorting: {
                                            sortModel: [{ field: 'openDatetime', sort: 'desc' }],
                                        },
                                    }}
                                    pageSizeOptions={[5, 10, 20]}
                                    disableRowSelectionOnClick

                                />
                            }
                        </Grid>

                    </Grid>
                </Paper>
            </Box>
            <HelpdeskModal
                show={ticketModal.show}
                onClose={() => setTicketModal({ ...ticketModal, show: false })}
                ticketData={ticketData}
                loading={ticketLoading}
            />
        </Container>
    );
};

export default UserHelpdesk;
