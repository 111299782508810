import { Settings } from "../Settings"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import big from '../assets/big.png'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';



const LoginScreen = () => {
  const theme = useTheme();
  sessionStorage.setItem("loginUrl", window.location.pathname)

  const [tokenAuth] = useMutation(gql`
  mutation TempTokenMutation ( $username: String!, $password: String! ) {
    tempToken ( username: $username, password: $password ) {
      token
    }
  }
`)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  return (

    <Container component="main" maxWidth="xs">


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          height: '100vh',
        }}
      >

        <img
          src={big}
          alt="big"
          height={100}
        />
        <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }} color={"primary"}>
          TimeTracking
        </Typography>
        {/* put the logo in the center */}

        <Button
          variant="contained"
          onClick={() => { window.location.assign(Settings.LOGIN_URL) }}
          sx={{
            p: 1,
            mt: 8,
            width: '65%',
            fontSize: '1.3rem',
            textTransform: 'none',
          }}
          color="primary"
        >
          Sign in
        </Button>
        {/* copyright */}
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2, mb: 2 }}>
          {'© Breast International Group'}
        </Typography>
        {["localhost", "172.16.10.2"].includes(window.location.hostname) &&
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
            <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Login for DEV</Typography>
              <TextField
                label="Email"
                onChange={e => setUsername(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                onChange={e => setPassword(e.target.value)}
              />
              <Button variant="outlined" onClick={async () => {
                try {
                  const { data } = await tokenAuth({ variables: { username, password } })
                  window.location.href = `/auth/${data?.tempToken?.token}`
                } catch {

                }
              }}>login</Button>
            </Stack>
          </Stack>
        }
      </Box>


    </Container>

  )
}

export default LoginScreen;