import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from './Theme';
import './App.css';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { Settings } from './Settings'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import AuthHandlerComponent from './auth/AuthHandlerComponent.jsx';
import AuthenticationRequired from './auth/AuthRequired';

import TimeTracking from './screens/TimeTracking/TimeTracking';
import UserHelpdesk from './screens/Helpdesk/UserHelpdesk';
import AdminHelpdesk from './screens/Helpdesk/AdminHelpdesk';

import { SnackbarProvider } from 'notistack';

import MiniDrawer from './utils/Drawer';
import { CurrentUserProvider } from './utils/CurrentUserContext';

import { ThemeProvider } from '@mui/material/styles';
import AdminTicket from './screens/Helpdesk/AdminTicket';
import ExportRecords from './screens/TimeTracking/ExportRecords.jsx';
import UserSettings from './screens/Settings/UserSettings.jsx';
import UserMangement from './screens/UserManagement/UserManagement.jsx';
import Reporting from './screens/Reporting/Dashboard.jsx';

const httpLink = createUploadLink({
    uri: Settings.GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(Settings.ACCESS_TOKEN)
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


function App() {

    return (
        <>
            <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}>
                        <Router>
                            <Routes>
                                <Route path="/auth/:accessToken" element={<AuthHandlerComponent />} />
                                <Route element={<AuthenticationRequired />}>
                                    <Route element={<CurrentUserProvider />}>
                                        <Route element={<MiniDrawer />}>
                                            <Route path="/" element={<TimeTracking />} />
                                            <Route path="/settings" element={<UserSettings />} />
                                            <Route path="/helpdesk" element={<UserHelpdesk />} />
                                            <Route path="/helpdesk-admin" element={<AdminHelpdesk />} />
                                            <Route path="/ticket/:id" element={<AdminTicket />} />
                                            <Route path="/export" element={<ExportRecords />} />
                                            <Route path="/user-management" element={<UserMangement />} />
                                            {/* <Route path="/reports" element={<Reporting />} /> */}
                                        </Route>
                                    </Route>
                                </Route>
                            </Routes>
                        </Router>
                    </SnackbarProvider>
                </ThemeProvider>
            </ApolloProvider>
        </>
    );
}

export default App;
