import React from "react"

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag'
import { Outlet } from 'react-router-dom'

export const CurrentUserContext = React.createContext()


const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      firstName
      lastName
      userprofile {
        mayAccessHelpdeskAdmin
        mayExportRecords
        maySeeAllRecords
        workSchedule
        mayEditUsers
      }
    }
  }
`

export const CurrentUserProvider = () => {
  const [currentUser, setCurrentUser] = React.useState(null)
  const { data, loading, error, refetch } = useQuery(ME_QUERY)
  if (loading) return "..."
    if (data && !currentUser) {
        setCurrentUser(data.me)
    }

  const flushCache = () => setCurrentUser(null)
  return (
    <CurrentUserContext.Provider value={{ currentUser, loading, flushCache, refetch }}>
      <Outlet />
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => React.useContext(CurrentUserContext)

