import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { QUERIES } from './config/queries';
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Stack, Typography, Box, Paper, Button, Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { UPDATE_HELPDESK_TICKET, CREATE_TICKET_COMMENT } from './config/queries';
import { Settings } from '../../Settings';
import moment from 'moment';
import { TicketState } from './config/config';
import { useSnackbar } from 'notistack';
import Avatar from '@mui/material/Avatar';

import TextField from '@mui/material/TextField';
import { useCurrentUser } from '../../utils/CurrentUserContext';
import CustomModal from '../../utils/CustomModal';
import { stringAvatar } from '../../utils/StringAvatar';

const AdminTicket = () => {
    const { currentUser } = useCurrentUser();
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { data, loading, error, refetch } = useQuery(QUERIES.HELPDESK_TICKET, {
        variables: {
            ticketId: id,
        },
    });
    const [updateTicket, { loading: mutationLoadning, error: mutationError }] = useMutation(UPDATE_HELPDESK_TICKET);
    const [createTicketComment, { loading: commentLoading, error: commentError }] = useMutation(CREATE_TICKET_COMMENT);

    const [ticket, setTicket] = useState(data?.helpdeskTicket);
    const [comment, setComment] = useState("");
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showCloseModal, setShowCloseModal] = useState(false);
    const [showOnHoldModal, setShowOnHoldModal] = useState(false);


    useEffect(() => {
        setTicket(data?.helpdeskTicket);
    }, [data]);
    if (loading || mutationLoadning) return <CircularProgress />
    if (error || mutationError) return <p>Error : {error}</p>;

    const handleStartIntervention = async () => {
        await updateTicket({
            variables: {
                ticketId: ticket.id,
                technician: currentUser.id,
                state: "Processing",
                interventionStartDatetime: moment().format(),
            }
        })
        await createTicketComment({
            variables: {
                ticketId: ticket.id,
                comment: "Intervention started",
            }

        })
        refetch();
        enqueueSnackbar("You have started to work on the ticket", { variant: "success" });
    }


    const handleComment = async () => {
        if (comment.length >= 3) {
            await createTicketComment({
                variables: {
                    ticketId: ticket?.id,
                    comment,
                }

            })
            refetch();
            enqueueSnackbar("Comment added", { variant: "success" });
            setShowCommentModal(false);
            setComment("");
        }
        else {
            enqueueSnackbar("Comment must be at least 3 characters long", { variant: "error" });
        }
    }

    const handleCloseTicket = async () => {
        await updateTicket({
            variables: {
                ticketId: ticket.id,
                state: "Closed",
                closeDatetime: moment().format(),
                interventionReport: ticket.interventionReport,
            }
        })
        setShowCloseModal(false);
        refetch();
        enqueueSnackbar("Ticket closed", { variant: "success" });
    }

    const handleOnHold = async () => {
        await updateTicket({
            variables: {
                ticketId: ticket.id,
                state: "On Hold",
            }
        })
        await createTicketComment({
            variables: {
                ticketId: ticket.id,
                comment: `[TICKET PUT ON HOLD]: ${comment}`,
            }
        })
        setShowOnHoldModal(false);
        setComment("");
        refetch();
        enqueueSnackbar("Ticket put on hold", { variant: "success" });
    }

    const handleStopHold = async () => {
        await updateTicket({
            variables: {
                ticketId: ticket.id,
                state: "Processing",
            }
        })
        await createTicketComment({
            variables: {
                ticketId: ticket.id,
                comment: '[TICKET RESUMED]',
            }
        })
        refetch();
        enqueueSnackbar("Ticket resumed", { variant: "success" });
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} md={8} >
                    <Paper sx={{
                        display: 'flex',
                        // this selector is used to apply styles to all direct children of the parent element that are not `style` elements.
                        // margin: 'auto',
                        p: 3,
                        mt: 5,
                    }}>
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>Ticket #{ticket?.id}</Typography>
                                    <TicketState state={ticket?.state} />
                                    <Stack direction="row" spacing={1} alignItems="center" sx={{ justifyContent: 'flex-end', flexGrow: 1 }}>
                                        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>{ticket?.user.firstName} {ticket?.user.lastName}</Typography>
                                    </Stack>
                                </Stack>
                            </Grid>


                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Divider />
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Title</Typography>
                                    <Typography variant="body1">{ticket?.title}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Box>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Description</Typography>
                                    <Typography variant="body1">{ticket?.description}</Typography>
                                </Box>
                            </Grid>
                            {ticket?.screenshot && (
                                <Grid item xs={12} sx={{ mb: 2, textAlign: 'center' }}>
                                    <Box>
                                        <a href={`${Settings.MEDIA_URL}/${ticket?.screenshot}`} target='blank'>
                                            <img src={`${Settings.MEDIA_URL}/${ticket?.screenshot}`} style={{ height: 300, width: "auto" }} />
                                        </a>
                                    </Box>
                                    <Divider />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box sx={{ mb: 2 }} >
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Intervention Report</Typography>
                                    <Typography variant="body1" >{ticket?.interventionReport ?
                                        ticket?.interventionReport : "No intervention report"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ mt: 3 }}>
                                <Box >
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold' }}>Urgency</Typography>
                                    <Typography variant="body1" >{ticket?.urgency}</Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', }}>Technician</Typography>
                                    <Typography variant="body1" >
                                        {ticket?.technician ?
                                            ticket?.technician?.firstName : "Not assigned yet"}
                                    </Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', }}>Intervention Start</Typography>
                                    <Typography variant="body1" >
                                        {ticket?.interventionStartDatetime ?
                                            moment(ticket?.interventionStartDatetime).format('LLL') : "N/A"}
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={6} md={6} sx={{ mt: 3 }}>
                                {/* <Box >
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Category</Typography>
                                    <Typography variant="body1">{ticket?.category?.name}</Typography>
                                </Box> */}
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Open Date</Typography>
                                    <Typography variant="body1">{moment(ticket?.openDatetime).format('LLL')}</Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Closed Date</Typography>
                                    <Typography variant="body1">
                                        {ticket?.closeDatetime ? moment(ticket?.closeDatetime).format('DD/MM/YYYY') : "N/A"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ mt: 2, mb: 2 }} />
                                {ticket?.helpdeskcommentSet.map((comment, index) => (
                                    <Box key={index} sx={{ mb: 3 }}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Avatar  {...stringAvatar(`${comment.technician.firstName} ${comment.technician.lastName}`)}/>
                                            <Typography variant="subtitle1">{`${comment.technician.firstName} ${comment.technician.lastName}`}</Typography>
                                            <Typography variant="caption" color="textSecondary" sx={{ flexGrow: 1, textAlign: 'right' }}>{moment(comment.creationDate).format('LLL')}</Typography>
                                        </Stack>
                                        <Typography sx={{ml: '37px'}} variant="body1">{comment.comment}</Typography>
                                    </Box>
                                ))
                                }
                            </Grid>


                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{
                        display: 'flex',
                        // this selector is used to apply styles to all direct children of the parent element that are not `style` elements.
                        margin: 'auto',

                        p: 3,
                        mt: 5,
                        "@media (max-width: 900px)": {
                            width: "100%",
                        },

                    }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} >
                                <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>Actions</Typography>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                            </Grid>

                            <Divider />

                            {!(ticket?.interventionStartDatetime) &&
                                <Grid item xs={12}>
                                    <Button onClick={handleStartIntervention} variant="contained" color="primary" sx={{ width: "100%" }}>Start Intervention</Button>
                                </Grid>
                            }

                            {ticket?.state == "Processing" &&
                                <Grid item xs={12}>
                                    <Button onClick={() => setShowCloseModal(true)} variant="contained" color="error" sx={{ width: "100%" }}>Close ticket</Button>
                                </Grid>
                            }
                            {(ticket?.state !== "Closed" && ticket?.state !== "On Hold") &&
                                <Grid item xs={12}>
                                    <Button onClick={() => setShowOnHoldModal(true)} variant="contained" color="warning" sx={{ width: "100%" }}>Put on hold</Button>
                                </Grid>
                            }
                            {ticket?.state == "On Hold" &&
                                <Grid item xs={12}>
                                    <Button onClick={handleStopHold} variant="contained" color="warning" sx={{ width: "100%" }}>Resume ticket</Button>
                                </Grid>
                            }

                            <Grid item xs={12}>
                                <Button onClick={() => setShowCommentModal(true)} variant="contained" color="secondary" sx={{ width: "100%" }}>New comment</Button>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {/* </Box> */}
            <CustomModal
                show={showCommentModal}
                handleCloseModal={() => setShowCommentModal(false)}
                handleSubmit={handleComment}
                title={"New comment"}
                validationButton={"Submit"}
            >
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={comment}
                    margin={'dense'}
                    onChange={(e) => setComment(e.target.value)}
                />
            </CustomModal>
            <CustomModal
                show={showCloseModal}
                handleCloseModal={() => setShowCloseModal(false)}
                handleSubmit={handleCloseTicket}
                title={"Close ticket"}
                validationButton={"Close ticket"}
            >
                <TextField
                    label="Intervention report"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={ticket?.interventionReport}
                    margin={'dense'}
                    onChange={(e) => setTicket({ ...ticket, interventionReport: e.target.value })}
                />
            </CustomModal>
            <CustomModal
                show={showOnHoldModal}
                handleCloseModal={() => setShowOnHoldModal(false)}
                handleSubmit={handleOnHold}
                title={"Set on hold"}
                validationButton={"Submit"}
            >
                <TextField
                    label="Explain why you put the ticket on hold"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={comment}
                    margin={'dense'}
                    onChange={(e) => setComment(e.target.value)}
                />
            </CustomModal>
        </Container>

    );
}

export default AdminTicket;
