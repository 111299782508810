import React, { useEffect, useState } from 'react';
import { useCurrentUser } from '../../utils/CurrentUserContext';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
// import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Calendar from './Calendar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import moment from 'moment';
import gql from 'graphql-tag';
import RecordModal from './RecordModal';
import DeleteConfirmationModal from '../../utils/DeleteConfirmationModal';

import { QUERIES } from './config/queries'
import {
  recordsDataGridColumns,
  getWeekDay,
  datesAreOnSameDay,
  dateDiffInDays,
  addDays,
  dateDiffToMinutes,
  minutesToHoursMinutes
} from '../../utils/config'


const TimeTracking = ({
  fromHR = false,
  userId = null,
}) => {


  // const { currentUser, loading, flushCache } = useCurrentUser()
  // const me = currentUser
  const initialState = {
    start: null,
    end: null,
    description: "",
    project: {
      name: "",
      id: "1",
    },
    newRecord: true,
    allDay: false,
    state: "active",
    id: "1",
    autoFill: false,
  }
  // STATES //
  const [modalRecord, setModalRecord] = useState(initialState)


  // const [fmLoading, setFmLoading] = useState(false)
  const [selectedDaysRecords, setSelectedDaysRecords] = useState([])

  const [modal, setModal] = useState({ show: false, title: "New Entry" })
  const [deleteModal, setDeleteModal] = useState({ show: false, title: "", body: "" })
  const [recordErrors, setRecordErrors] = useState({ show: false })
  const [expectedWorkingMinutes, setExpectedWorkingMinutes] = useState(0)
  const [dailyMinutesWorked, setDailyMinutesWorked] = useState(0)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // QUERIES //
  const { data: userProjects, loading: projectLoading, error: projectError, refetch: refetchProjects } = useQuery(QUERIES.ALL_PROJECTS_BY_USER,
    {
      variables: { userId: userId }
    })

  const { data: workingSchedule, loading: _loading } = useQuery(gql`
  query userProfile {
      userProfile {
          id
          workSchedule
      }
  }`)

  const { data, loading: dataLoading, error, refetch, fetchMore } = useQuery(QUERIES.MY_RECORDS, {
    variables: {
      start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      end: moment().startOf('isoWeek').add(6, 'days').format('YYYY-MM-DD'),
      allRecords: false,
      userId: userId
    }
  })

  // MUTATIONS //
  const [CreateRecord, { data: createMutationData, loading: mutationLoading }] = useMutation(QUERIES.CREATE_RECORD)
  const [EditRecord, { data: editMutationData, loading: editMutationLoading, error: editMutationError }] = useMutation(QUERIES.EDIT_RECORD)

  useEffect(() => {
    if (data) {
      setSelectedDaysRecords(data.recordsByUser.filter(e => datesAreOnSameDay(new Date(e.start), new Date(modalRecord.start))))
      refetchProjects()
    }
  }, [modalRecord])

  // if (dataLoading || projectLoading || editMutationLoading || mutationLoading) return <CircularLoading />
  if (dataLoading || projectLoading || editMutationLoading || mutationLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  )

  if (editMutationError) return 'mutation error...'
  if (error) return 'error...'
  if (projectError) return 'project error...'

  const handleCloseModal = () => {
    setModal({ ...modal, show: false })
    setRecordErrors({ ...recordErrors, show: false })
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ ...deleteModal, show: false })
  };

  const handleFormInput = (field, e) => {
    setModalRecord(modalRecord => ({ ...modalRecord, [field]: e }))
  }

  const handleShowModal = (rec) => {
    let defaultRecord = {
      start: rec.start,
      end: rec.end,
      project: {
        id: 0,
        name: ""
      },
      newRecord: rec.newRecord,
      allDay: rec.allDay ? true : false,
      state: "active",
      description: "",
    }

    !rec.newRecord && (defaultRecord =
    {
      ...defaultRecord,
      project: { id: rec.projectId, name: rec.projectName },
      description:
        rec.description,
      id: rec.id
    })

    setModalRecord(defaultRecord)

    const title = rec.newRecord ? rec.allDay ? "Create Full Day Record" : "Create Record" : "Edit Record"
    setModal({ ...modal, show: true, title, readOnly: !rec.newRecord, deleteConfirmation: false })
  }

  const handleModalEdit = () => {
    setModal({ ...modal, readOnly: false })
  }
  const handleModalRecordValidation = () => {
    let errorInForm = false
    let recordsOnTheSameDay = []
    let errors = {
      overlapingEvent: "",
      workingHours: "",
      projectDoesNotExist: "",
      endBeforeStart: "",
      differentDay: "",
      show: false,
    }

    // Get other events on the same date
    modalRecord.newRecord ?
      recordsOnTheSameDay = data.recordsByUser.filter(e => datesAreOnSameDay(new Date(e.start), modalRecord.start)) :
      recordsOnTheSameDay = data.recordsByUser.filter(e => datesAreOnSameDay(new Date(e.start), modalRecord.start) && e.id != modalRecord.id)


    // if (modalRecord.start.getHours() < 6 || modalRecord.end.getHours() > 20) {
    //   errors = { ...errors, workingHours: "Please select a time between working hours!" }
    //   // setRecordErrors({ ...recordErrors, workingHours: "Please select a time between working hours" })
    //   errorInForm = true
    // }

    if (modalRecord.end < modalRecord.start) {
      errors = { ...errors, endBeforeStart: "Your end date is before your start date!" }
      errorInForm = true
    }


    if (!modalRecord.allDay && dateDiffInDays(modalRecord.start, modalRecord.end)) {
      errors = { ...errors, differentDay: "Your start is not on the same day as your end date!" }
      errorInForm = true
    }

    // Check for overlap
    recordsOnTheSameDay.forEach(e => {
      const start = new Date(e.start)
      const end = new Date(e.end)
      if
        // Is the start of the new event in another event range
        ((modalRecord.start >= start && modalRecord.start < end) ||
        // Is the end of the new event in another event range
        (modalRecord.end > start && modalRecord.end <= end) ||
        // Is there an other event inside my new event range
        (modalRecord.start <= start && modalRecord.end >= end)) {
        errors = { ...errors, overlapingEvent: "There is an overlap with a another record!" }
        errorInForm = true
        return true
      }
    })

    //Check if the project name matches one of the selected projects
    const projectExist = () => {
      let exist = false
      userProjects.allProjectsByUser.forEach(e => {
        if (e.name === modalRecord.project.name) {
          exist = e.active
          return true
        }
      })
      return exist
    }

    if (!projectExist()) {
      errors = { ...errors, projectDoesNotExist: "Please select a valid project!" }
      errorInForm = true
    }

    setRecordErrors({ ...errors, show: errorInForm })

    return errorInForm
  }

  const hasOverlappingEvents = (dateDiff, start) => {
    for (let i = 0; i <= dateDiff; i++) {
      const recordsOnTheSameDay = data.recordsByUser.filter(e => datesAreOnSameDay(new Date(e.start), addDays(start, i)))
      if (recordsOnTheSameDay.length > 0) {
        setRecordErrors({ ...recordErrors, overlapingEvent: `There is already a record created on ${moment(addDays(start, i)).format('DD/MM/YYYY')}`, show: true })
        return true
      }
    }
    return false
  }

  const isWeekendDay = (date) => (date.getDay() === 0 || date.getDay() === 6)

  const setTimeToDate = (date, time) => {
    const [hours, minutes] = time.split(':');
    let newDate = new Date(date)
    newDate.setHours(parseInt(hours), parseInt(minutes));
    return newDate
  }

  const newRecord = async (record) => {
    let variables = {
      ...record,
      projectId: record.project.id,
    }
    if (fromHR) { variables.userId = userId }
    await CreateRecord({
      variables: variables
    })
  }

  // GQL Mutations //
  const handleRecordMutation = async () => {

    if (handleModalRecordValidation()) {
      return
    }

    const dateDiff = dateDiffInDays(modalRecord.start, modalRecord.end)

    if (modalRecord.newRecord) {
      // If allDay has been selected
      if (modalRecord.allDay) {
        if (hasOverlappingEvents(dateDiff, modalRecord.start)) {
          return
        }

        // loop over each day and create a record  
        for (let i = 0; i <= dateDiff; i++) {
          let currentLoopDay = addDays(modalRecord.start, i)

          // Skip if date is during the weekend
          if (isWeekendDay(currentLoopDay)) {
            continue
          }
          // returns the current day schedule {start_time: "08:00", end_time: "16:00", break_start: "12:00", break_end: "13:00"}
          const daySchedule = workingSchedule.userProfile.workSchedule[moment(currentLoopDay).format('ddd')];

          // Skip if day is not in the schedule (day off)
          if (Object.keys(daySchedule).length === 0) {
            continue
          }

          // Create one event if part time
          if (daySchedule.break_start == "") {
            await newRecord({
              ...modalRecord,
              start: setTimeToDate(currentLoopDay, daySchedule.start_time),
              end: setTimeToDate(currentLoopDay, daySchedule.end_time)
            })
          }

          // Create two events if full time
          else {

            // Create record for time period from start_time to break_start
            await newRecord({
              ...modalRecord,
              start: setTimeToDate(currentLoopDay, daySchedule.start_time),
              end: setTimeToDate(currentLoopDay, daySchedule.break_start),
            })

            // Create record for time period from break_end to end_time
            await newRecord({
              ...modalRecord,
              start: setTimeToDate(currentLoopDay, daySchedule.break_end),
              end: setTimeToDate(currentLoopDay, daySchedule.end_time)
            })
          }
        }
        enqueueSnackbar(`Records successfully created!`, { variant: "success", });
      }
      // If normal record has been selected
      else {
        if (modalRecord.autoFill) {
          const [break_start_hours, break_start_minutes] = workingSchedule.userProfile.workSchedule[getWeekDay(modalRecord.start)].break_start.split(':')
          const break_start = moment(modalRecord.start).set({ hour: break_start_hours, minute: break_start_minutes })
          const [break_end_hours, break_end_minutes] = workingSchedule.userProfile.workSchedule[getWeekDay(modalRecord.start)].break_end.split(':')
          const break_end = moment(modalRecord.start).set({ hour: break_end_hours, minute: break_end_minutes })

          // Create the first part of the day event
          await newRecord({
            ...modalRecord,
            end: break_start.toDate()
          })

          // Create the second part of the day event
          await newRecord({
            ...modalRecord,
            start: break_end.toDate()
          })
        }
        else {
          await newRecord(modalRecord)
          enqueueSnackbar("Record successfully created!", { variant: "success", });
        }
      }
    }
    // If existing record has been selected
    else {
      await EditRecord({ variables: { ...modalRecord, projectId: modalRecord.project.id } })
      enqueueSnackbar('Record successfully modified!', { variant: "success", });
      refetch()

    }
    setModal({ ...modal, show: false })
    setModalRecord(initialState)

    //this is used to fix a bug where the calendar doesn't update the current week
    if (moment().isoWeek() == moment(modalRecord.start).isoWeek()) {
      refetch()
    }
    else {
      fetchMoreFunc(modalRecord.start, modalRecord.end)
    }
  }

  const handleRecordDelete = async () => {
    await EditRecord({ variables: { ...modalRecord, state: "deleted", projectId: modalRecord.project.id } })
    setDeleteModal({ ...deleteModal, show: false })
    fetchMoreFunc(modalRecord.start, modalRecord.end)
    enqueueSnackbar('Record successfully deleted!', { variant: "success", });
  }

  const handleEventResize = async (variables) => {
    await EditRecord({ variables: variables })
    await fetchMoreFunc(variables.start, variables.end)
    enqueueSnackbar('Record successfully moved!', { variant: "success", });
  }

  // Wrapping fetchMore function to reuse it on different events
  const fetchMoreFunc = async (start, end, allRecords = null) => {
    const removeDuplicatesById = (arr) => {
      return arr.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        return x ? acc : acc.concat([current]);
      }, []);
    }

    let variables = { start, end }
    // setFmLoading(true)
    if (allRecords) {
      refetch(variables)
      // setFmLoading(false)
      return
    }

    if (!allRecords) {
      variables = {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      }
    }
    await fetchMore({
      variables,
      updateQuery: (prevResult, { fetchMoreResult }) => {
        // This is used to remove the event after delete from previous result idk how it goes to this list 
        const pv = prevResult.recordsByUser.filter(rec => rec.state == "active")
        fetchMoreResult.recordsByUser = [
          ...pv, ...fetchMoreResult.recordsByUser
        ]
        fetchMoreResult.recordsByUser = removeDuplicatesById(fetchMoreResult.recordsByUser)
        return fetchMoreResult;
      }
    })
    // setFmLoading(false)
  }
  const DeleteMessage = () => (
    <Box>
      <Typography variant="h6" gutterBottom>
        Are you sure you want to delete this record?
        <br />
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Project: {modalRecord.project.name} <br />
        Date: {moment(modalRecord.start).format('DD/MM/YYYY')} <br />
        Time: {minutesToHoursMinutes(dateDiffToMinutes(modalRecord.start, modalRecord.end))} <br />
      </Typography>
      <Typography variant="body2" gutterBottom>

        This action cannot be undone.
      </Typography>
    </Box>
  )


  return (
    <Container maxWidth="xl" sx={{ mt: 1 }}>
      {dataLoading && <p>Loading...</p>}

      <Calendar
        handleShowModal={handleShowModal}
        records={data.recordsByUser}
        handleEventResize={handleEventResize}
        fetchMoreFunc={fetchMoreFunc}
        setExpectedWorkingMinutes={setExpectedWorkingMinutes}
        setDailyMinutesWorked={setDailyMinutesWorked}
        fromHR={fromHR}
      />
      {modal.show && !deleteModal.show &&
        <RecordModal
          show={modal.show}
          title={modal.title}
          handleCloseModal={handleCloseModal}
          onDelete={() => {
            handleCloseModal()
            setDeleteModal({
              ...deleteModal,
              show: true,
              title: "Delete Record",
              body: <DeleteMessage />
            })
          }}
          onSave={handleRecordMutation}
          record={modalRecord}
          linkedProjects={userProjects.allProjectsByUser}
          handleChange={handleFormInput}
          recordErrors={recordErrors}
          dailyMinutesWorked={dailyMinutesWorked}
          expectedWorkingMinutes={expectedWorkingMinutes}
          fromHR={fromHR}
          selectedDayRecords={selectedDaysRecords}
          workingSchedule={workingSchedule.userProfile.workSchedule}
        />}

      {deleteModal.show &&

        <DeleteConfirmationModal
          show={deleteModal.show}
          title={deleteModal.title}
          body={deleteModal.body}
          handleCloseModal={handleCloseDeleteModal}
          handleDelete={handleRecordDelete}
        />
      }
    </Container>
  )
}

export default TimeTracking;
