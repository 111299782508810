const hostname = window.location.hostname

export const backendUrl = (hostname === "timetracking.bigagainstbc.org")
    ? "https://timetracking.bigagainstbc.org"
    : (hostname === "tt.bigagainstbc.org")
    ? "https://tt.bigagainstbc.org"
    : (hostname === "172.16.10.2")
    ? "https://172.16.10.2:8000"
    : `http://${hostname}:8000`;
    
export const Settings = {
    ACCESS_TOKEN: 'time-tracking.accessToken',
    REFRESH_TOKEN: 'time-tracking.refreshToken',
    LS_TOKEN_EXPIRES_IN: 'time-tracking.tokenExpiresIn',
    GRAPHQL_URI: `${backendUrl}/graphql`,
    LOGIN_URL: `${backendUrl}/oidc/authenticate/`,
    MEDIA_URL: `${backendUrl}/media/`,
    HOME_PAGE: '/',
    ACCESS_TOKEN_REFRESH_IN_SECONDS: 120,
}

export const bridgeDays = [
    '2024-05-10',
    '2024-08-16',
]