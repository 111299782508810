import { useState, useEffect } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';

const UserSettings = ({ userId }) => {
    const { data, loading, error, refetch } = useQuery(gql`
        query UserProfile($userId: ID!) {
            userProfile(userId: $userId) {
                user {
                    firstName
                    email
                    lastName
                }
                ttReminderActive
                bigUser
            }
        }`,
        {
            variables: {
                userId
            }
        }
    );

    const [updateUserProfile] = useMutation(gql`
        mutation UpdateUserProfile($userId: ID!, $ttReminderActive: Boolean, $bigUser: Boolean) {
            updateUserProfile(userId: $userId, ttReminderActive: $ttReminderActive, bigUser: $bigUser) {
                id
            }
        }
    `);

    const [ttReminderActive, setTTReminderActive] = useState(false);
    const [bigUser, setBigUser] = useState(false);

    useEffect(() => {
        if (data) {
            setTTReminderActive(data.userProfile.ttReminderActive);
            setBigUser(data.userProfile.bigUser);
        }
    }, [data]);

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt:5 }}>
            <CircularProgress />
        </Box>
    )
    if (error) return <p>Error: {error.message}</p>

    const handleTTReminderActiveChange = async (event) => {
        await updateUserProfile({
            variables: {
                userId,
                ttReminderActive: event.target.checked
            }
        });
        refetch();
    }
    const handleBigUserChange = async (event) => {
        let newTTReminderActive = ttReminderActive;
        if (!event.target.checked) {
            newTTReminderActive = false;
        }
        await updateUserProfile({
            variables: {
                userId,
                bigUser: event.target.checked,
                ttReminderActive: newTTReminderActive
            }
        });
        refetch();
    }

    const { userProfile } = data;

    const rows = [
        { label: 'First Name:', value: userProfile.user.firstName || 'N/A' },
        { label: 'Last Name:', value: userProfile.user.lastName || 'N/A' },
        { label: 'Email:', value: userProfile.user.email || 'N/A' },
        {
            label: 'User Active:',
            value: (
                <Switch
                    checked={bigUser}
                    onChange={handleBigUserChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            ),
        },
        {
            label: 'Time Tracking Reminder:',
            value: (
                <Switch
                    checked={ttReminderActive}
                    onChange={handleTTReminderActiveChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            ),
        },
    ];
    return (
        <Box sx={{ width: '60%', mt: 2 }}>
            <Table size="small">
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ borderBottom: 0 }}>{row.label}</TableCell>
                            <TableCell sx={{ borderBottom: 0 }}>{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}

export default UserSettings