import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const CustomModal = ({
    show,
    handleCloseModal,
    handleSubmit,
    title,
    validationButton,
    children
}) => {

    return (
        <Dialog open={show} onClose={handleCloseModal} fullWidth>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }} color='primary'>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleCloseModal} color="error" >Cancel</Button>
                <Button variant="contained" onClick={handleSubmit} >{validationButton}</Button>
            </DialogActions>
        </Dialog>
    )

}

export default CustomModal;