import { Navigate, useParams } from 'react-router-dom'
import { Settings } from '../Settings'


const AuthHandlerComponent = () => {
    const { accessToken } = useParams()
    localStorage.setItem(Settings.ACCESS_TOKEN, accessToken);
    return <Navigate to={sessionStorage.getItem("loginUrl") == "/" ? Settings.HOME_PAGE : sessionStorage.getItem("loginUrl")} />
  }

export default AuthHandlerComponent;