
import moment from 'moment';
import 'moment/locale/en-gb';
import { useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


const CustomTimePicker = (props) => {
    const { label, value, handleChange, maxTime, minTime } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <TimePicker
                label={label}
                slotProps={{ textField: { size: 'small' } }}
                value={value}
                onChange={(e) => handleChange(e)}
                timeSteps={{ minutes: 1 }}
                maxTime={maxTime}
                minTime={minTime}
            />
        </LocalizationProvider>
    )
}
export default CustomTimePicker