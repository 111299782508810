import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Stack } from '@mui/material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { minutesToHoursMinutes } from '../../utils/config';

import CustomDatePicker from '../../utils/CustomDatePicker';

const columns = [
    { field: "user", headerName: "User", valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName}`, width: 170 },
    { field: 'email', headerName: 'Email', valueGetter: (params) => `${params.row.user.email}`, width: 250 },
    {
        field: 'minutesWorked', headerName: 'Hours worked',
        valueFormatter: (params) => `${minutesToHoursMinutes(params.value)}`, width: 200
    },
    {
        field: 'absenceMinutes', headerName: 'Absence',
        valueFormatter: (params) => `${minutesToHoursMinutes(params.value)}`, width: 200
    },
    {
        field: 'totalHours', headerName: 'Total hours',
        valueGetter: (params) => params.row.minutesWorked + params.row.absenceMinutes, width: 200,
        valueFormatter: (params) => `${minutesToHoursMinutes(params.value)}`
    },
    {
        field: 'expectedMinutes', headerName: 'Expected hours',
        valueFormatter: (params) => `${minutesToHoursMinutes(params.value)}`, width: 200
    },
    {
        field: 'overtimeMinutes', headerName: 'Overtime',
        valueFormatter: (params) => `${minutesToHoursMinutes(params.value)}`, width: 200
    },

]

const UserTable = () => {

    const [range, setRange] = useState({ 
        start: moment().subtract(30, 'days'),
        end: moment() 
    })
    const [rows, setRows] = useState([])

    const { data, error, loading, refetch } = useQuery(gql`
        query hoursOverview($start: String!, $end: String!) {
            hoursOverview(start: $start, end: $end) {
            absenceMinutes
            expectedMinutes
            minutesWorked
            overtimeMinutes
            user {
                email
                firstName
                lastName
            }
            }
        }
    `, {
        variables: {
            start: range.start.format('YYYY-MM-DD'),
            end: range.end.format('YYYY-MM-DD'),
        },
        onCompleted: (data) => {
            const rowsWithId = data.hoursOverview.map((row, index) => ({ id: index, ...row }));
            setRows(rowsWithId);
        },
    })

    const handleChangeRange = (identifier, date) => {
        setRange(prevRange => {
            const newRange = { ...prevRange, [identifier]: moment(date) };
            refetch({
                start: newRange.start.format('YYYY-MM-DD'),
                end: newRange.end.format('YYYY-MM-DD'),
            });
            return newRange;
        });
    }

    return (
        <>
            <Stack direction="row" spacing={1} sx={{ mb: 5 }}>
                <CustomDatePicker
                    label="Start"
                    defaultValue={range.start}
                    handleChange={handleChangeRange}
                    identifier="start"
                    maxDate={range.end}
                />
                <CustomDatePicker
                    label="End"
                    defaultValue={range.end}
                    handleChange={handleChangeRange}
                    identifier="end"
                    minDate={range.start}
                />
            </Stack>

            <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                />
            </div>
        </>
    )
}

export default UserTable