const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};

const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name) => {
    const hRange = [50, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];
    
    const hash = getHashOfString(name);
    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);
    return [h, s, l];
};

const HSLtoString = (hsl) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

export const stringAvatar = (name) => {
    return {
        sx: {
            bgcolor: HSLtoString(generateHSL(name)),
            width: 30,
            height: 30,
            fontSize: 12,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
