import React from 'react';
import Button from '@mui/material/Button';
import moment from 'moment';

export const getTimeBetweenDates = (params) => {
  const diffMs = (new Date(params.getValue('end')) - new Date(params.getValue('start')))
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


  return (diffHrs > 0 && diffMins > 0) ? diffHrs + 'h' + diffMins + 'm'
    : (diffMins > 0) ? diffMins + 'm'
      : (diffHrs > 0) ? diffHrs + 'h'
        : ""
}

export const recordsDataGridColumns = [

  {
    field: 'project',
    headerName: 'Project',
    flex: 1,
    valueGetter: (params) => params.value.name,
    renderCell: (params) => (
      <Button
        variant="outlined"
        style={{ marginLeft: 16, backgroundColor: params.value.category.color, border: 'none' }}
      >
        {params.value.name}

      </Button>
    ),
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 130,
    valueGetter: (params) => params.row.project.category.name,
  },
  // { field: 'description', headerName: 'Description', width: 130 },
  {
    field: 'start',
    headerName: 'Start',
    width: 200,
    valueFormatter: (params) => new Date(params.value).toLocaleString('en-GB'),
  },
  {
    field: 'end',
    headerName: 'End',
    width: 200,
    valueFormatter: (params) => new Date(params.value).toLocaleString('en-GB'),
  },
  {
    field: 'time',
    headerName: 'Duration',
    width: 120,
    valueGetter: getTimeBetweenDates,
  },
];

export const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const isWeekday = date => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};


export const dateDiffInDays = (a, b) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

// Calculate the hours and minutes between two dates
export const dateDiffToMinutes = (a, b) => {
  const diffMs = (b - a)
  const diffMins = Math.round(diffMs / 60000)// minutes
  return diffMins
}

// Render minutes to human readable hours and minutes
export const minutesToHoursMinutes = (minutes) => {
  const diffHrs = Math.floor(minutes / 60); // hours
  const diffMins = Math.round(minutes % 60); // minutes
  const paddedMins = String(diffMins).padStart(2, '0');
  return (diffHrs > 0 && diffMins > 0) ? diffHrs + 'h' + paddedMins
    : (diffMins > 0 && diffMins < 10 ) ? diffMins + 'm'
    : (diffMins > 0) ? diffMins + 'm'
      : (diffHrs > 0) ? diffHrs + 'h'
        : ""
}


export const getEaster = (year) => {
  var f = Math.floor,
    // Golden Number - 1
    G = year % 19,
    C = f(year / 100),
    // related to Epact
    H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
    // number of days from 21 March to the Paschal full moon
    I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
    // weekday for the Paschal full moon
    J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
    // number of days from 21 March to the Sunday on or before the Paschal full moon
    L = I - J,
    month = 3 + f((L + 40) / 44),
    day = L + 28 - 31 * f(month / 4);
  return [month, day];
}

export const addDays = (date, days) => {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}

export const getPublicHolidays = (year) => {

  const [month, day] = getEaster(year)
  const easter = new Date(year, month - 1, day)

  return [
    {
      name: "New Year's Day",
      date: new Date(year, 0, 1)
    },
    {
      name: "Easter",
      date: easter
    },
    {
      name: "Easter Monday",
      date: addDays(easter, 1)
    },
    {
      name: "Labor Day",
      date: new Date(year, 4, 1)
    },
    {
      name: "Ascension Day",
      date: addDays(easter, 39)
    },
    {
      name: "Whit Sunday",
      date: addDays(easter, 49)
    },
    {
      name: "Whit Monday",
      date: addDays(easter, 50)
    },
    {
      name: "Belgian National Day",
      date: new Date(year, 6, 21)
    },
    {
      name: "Assumption of Mary",
      date: new Date(year, 7, 15)
    },
    {
      name: "All Saints' Day",
      date: new Date(year, 10, 1)
    },
    {
      name: "Armistice Day",
      date: new Date(year, 10, 11)
    },
    {
      name: "Christmas Day",
      date: new Date(year, 11, 25)
    },
  ]
}


export const getPublicHolidaysEvents = () => {
  const currentYear = new Date().getFullYear()
  const pastYear = currentYear - 1
  const nextYear = currentYear + 1
  let holidays = []

  getPublicHolidays(pastYear).forEach(holiday => holidays.push(holiday))
  getPublicHolidays(currentYear).forEach(holiday => holidays.push(holiday))
  getPublicHolidays(nextYear).forEach(holiday => holidays.push(holiday))

  const events = holidays.map(holiday => ({
    start: holiday.date,
    title: holiday.name,
    allDay: true,
    color: "#a8326d",
    editable: false,
    startEditable: false,
  }))

  return events
}

export const getWeekDay = (date) => {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()]
}

export const getExpectedWorkingMinutes = (workingSchedule, day) => {
  if (['Sat', 'Sun'].includes(day)) {
    return 0;
  }

  const startTime = moment(workingSchedule[day].start_time, "HH:mm");
  const endTime = moment(workingSchedule[day].end_time, "HH:mm");

  let breakDuration = moment.duration();
  if (workingSchedule[day].break_start && workingSchedule[day].break_end) {
    const breakStart = moment(workingSchedule[day].break_start, "HH:mm");
    const breakEnd = moment(workingSchedule[day].break_end, "HH:mm");
    breakDuration = moment.duration(breakEnd.diff(breakStart));
  }

  const duration = moment.duration(endTime.diff(startTime)).subtract(breakDuration);

  return duration.asMinutes();
}

export const getExpectedWorkingHours = (workingSchedule, day) => {
  const minutes = getExpectedWorkingMinutes(workingSchedule, day)
  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60

  return `${hours}h${minutesLeft < 10 ? '0' : ''}${minutesLeft}`
}