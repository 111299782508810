import { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import TimeTracking from '../TimeTracking/TimeTracking';
import ProjectSelection from '../Settings/ProjectSelection';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


import WorkingSchedule from './WorkingScheduleAdmin';
import BasicTabs from './BasicTabs';
import UserSettings from './UserSettings';
import UserTable from './UserTable';

const UserMangement = () => {

    const [selectedUser, setSelectedUser] = useState(null);
    const { data, loading, error } = useQuery(gql`
        query MyQuery {
        users {
            email
            firstName
            lastName
            id
        }
        }
    `)
    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    )

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Paper sx={{
                        p: 2,
                        overflow: 'auto',
                        minHeight: '300px',
                        minWidth: '875px',

                    }}>
                        <BasicTabs
                            tabs={['TimeTracking', 'Working Schedule', 'Projects', 'Settings']}
                            content={[
                                <TimeTracking fromHR={true} userId={selectedUser} />,
                                <WorkingSchedule userId={selectedUser} />,
                                <ProjectSelection userId={selectedUser} />,
                                <UserSettings userId={selectedUser} />,
                            ]}
                            data={data}
                            setSelectedUser={setSelectedUser}
                            selectedUser={selectedUser}
                        />
                    </Paper>

                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{
                        p: 2,
                        overflow: 'auto',
                        minHeight: '300px',
                        minWidth: '875px',

                    }}>
                        <UserTable />
                    </Paper>

                </Grid>
            </Grid>
        </Container>
    )
}
export default UserMangement;
