import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PeopleIcon from '@mui/icons-material/People';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AccountMenu from './AccountMenu';
import { useCurrentUser } from './CurrentUserContext';

import { Outlet, Link } from 'react-router-dom'

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function MiniDrawer(props) {
    const { currentUser } = useCurrentUser();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const sections = [
        { text: "Calendar", icon: <CalendarMonthOutlinedIcon />, path: "/", perms: "None" },
        { text: "Export", icon: <FileDownloadOutlinedIcon />, path: "/export", perms: "mayExportRecords" },
        // { text: "Reports", icon: <AssessmentOutlinedIcon />, path: "/reports", perms: "None" },
        { text: "Helpdesk", icon: <ComputerIcon />, path: "/helpdesk", perms: "None" },
        { text: "Helpdesk Admin", icon: <ComputerIcon />, path: "/helpdesk-admin", perms: "mayAccessHelpdeskAdmin" },
        { text: "User Management", icon: <PeopleIcon />, path: "/user-management", perms: "mayEditUsers" },
    ];
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={open} sx={{ height: 64, bgcolor: '#fff' }}>
                <Toolbar>
                    <IconButton
                        color="primary.main"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" noWrap component="div" sx={{ color: 'primary.main', fontWeight: 600 }}>
                        TimeTracking
                    </Typography>

                    {/* <IconButton
                        color="inherit"
                        aria-label="account"
                        // edge="end"
                        sx={{
                            marginLeft: 'auto',
                        }}

                    > */}
                    <Box sx={{ marginLeft: 'auto' }}>
                        <AccountMenu />
                    </Box>
                    {/* </IconButton> */}
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {sections.map((section, index) => {
                        if (section.perms != "None") {
                            if (currentUser.userprofile[section.perms]) {
                                return <Link to={section.path} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <ListItem disablePadding sx={{ display: 'block', '&:hover': { color: 'primary.main' } }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,

                                            }}
                                        >

                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                    color: 'inherit',
                                                }}
                                            >
                                                {section.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={section.text} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            }

                            return null;

                        }

                        return (
                            <Link to={section.path} key={index} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItem key={section.text} disablePadding sx={{ display: 'block', '&:hover': { color: 'primary.main' } }}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >

                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                                color: 'inherit',
                                            }}
                                        >
                                            {section.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={section.text} sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        )
                    }
                    )}

                    <a href={"https://timetracking.bigagainstbc.org/records/trainings"} target='_blank' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <ListItem disablePadding sx={{ display: 'block', '&:hover': { color: 'primary.main' } }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: 'inherit',
                                    }}
                                >
                                    <SchoolOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Trainings" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    </a>
                </List>

            </Drawer>
            <Box component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    p: 3,
                }}
            >
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}