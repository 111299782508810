import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Button from '@mui/material/Button';
// import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { getExpectedWorkingHours } from '../../utils/config';

import gql from 'graphql-tag';


const WorkingSchedule = () => {

    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    const { data, loading } = useQuery(gql`
        query userProfile {
            userProfile {
                id
                workSchedule
            }
        }`)

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    )
    const workingSchedule = data.userProfile.workSchedule



    return (

        <Paper sx={{
            p: 2,
            overflow: 'auto',
            minHeight: '300px',

        }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h2">
                        My Working Schedule
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <TableContainer component={Paper} sx={{ maxWidth: 600, minWidth: 500 }}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontWeight: 'bold' }}>Day</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">Start</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">Break Start</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">Break End</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">End</TableCell>
                                        <TableCell style={{ fontWeight: 'bold' }} align="right">Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {days.filter((day) => Object.keys(workingSchedule[day]).length).map((day, index) => (

                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">{day}</TableCell>
                                            <TableCell align="right">
                                                {workingSchedule[day].start_time ? moment(workingSchedule[day].start_time, 'HH:mm').format('HH:mm') : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {workingSchedule[day].break_start ? moment(workingSchedule[day].break_start, 'HH:mm').format('HH:mm') : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {workingSchedule[day].break_end ? moment(workingSchedule[day].break_end, 'HH:mm').format('HH:mm') : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {workingSchedule[day].end_time ? moment(workingSchedule[day].end_time, 'HH:mm').format('HH:mm') : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">
                                                {getExpectedWorkingHours(
                                                    workingSchedule, day
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </Paper>

    )
}

export default WorkingSchedule