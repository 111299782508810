import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Settings } from '../../Settings';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { TicketState } from './config/config';


const HelpdeskModal = ({
    ticketData,
    show,
    onClose,
    loading,

}) => {

    return (
        <Dialog open={show} maxWidth="md" onClose={onClose} fullWidth>
            {loading ? (
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <DialogTitle color='primary' sx={{ fontWeight: 'bold', lineHeight: 'normal' }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box>Ticket#{ticketData?.helpdeskTicket?.id}</Box>
                            <TicketState state={ticketData?.helpdeskTicket?.state} />
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <Divider sx={{ mb: 3 }} />

                        <Stack spacing={3} >
                            <Box >
                                <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Title</Typography>
                                <Typography variant="body1">{ticketData?.helpdeskTicket?.title}</Typography>
                            </Box>

                            <Box >
                                <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', }}>Description</Typography>
                                <Typography variant="body1" >{ticketData?.helpdeskTicket?.description}</Typography>
                            </Box>
                            <Divider />


                            {ticketData?.helpdeskTicket?.screenshot && (
                                <Box sx={{ mt: 2, textAlign: 'center' }}>
                                    <a href={`${Settings.MEDIA_URL}${ticketData?.helpdeskTicket?.screenshot}`} target='blank'>
                                        <img src={`${Settings.MEDIA_URL}${ticketData?.helpdeskTicket?.screenshot}`} style={{ height: 300, width: "auto" }} />
                                    </a>
                                    <Divider />
                                </Box>
                            )}

                        </Stack>
                        <Grid container sx={{ mt: 3 }} >
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Intervention Report</Typography>
                                    <Typography variant="body1" >{ticketData?.helpdeskTicket?.interventionReport ?
                                        ticketData?.helpdeskTicket?.interventionReport : "No intervention report"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ mt: 3 }}>
                                <Box >
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold' }}>Urgency</Typography>
                                    <Typography variant="body1" >{ticketData?.helpdeskTicket?.urgency}</Typography>
                                </Box>
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', }}>Technician</Typography>
                                    <Typography variant="body1" >{ticketData?.helpdeskTicket?.technician ?
                                        ticketData.helpdeskTicket.technician.firstName : "Not assigned yet"}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={{ mt: 3 }}>
                                {/* <Box >
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Category</Typography>
                                    <Typography variant="body1">{ticketData?.helpdeskTicket?.category?.name}</Typography>
                                </Box> */}
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="h7" color="primary" sx={{ fontWeight: 'bold', mt: 2 }}>Open Date</Typography>
                                    <Typography variant="body1">{moment(ticketData?.helpdeskTicket?.openDatetime).format('DD/MM/YYYY')}</Typography>
                                </Box>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </>)}
            <DialogActions>
                <Button variant="contained" sx={{ m: 2 }} onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HelpdeskModal