
import moment from 'moment';
import 'moment/locale/en-gb';
import { useState } from 'react';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDatePicker = (props) => {
    const { label, defaultValue, handleChange, identifier, maxDate, minDate } = props;
    const [open, setOpen] = useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DatePicker
                open={open}
                maxDate={maxDate}
                minDate={minDate}
                defaultValue={moment(defaultValue)}
                onClose={() => setOpen(false)}
                label={label}
                onChange={(e) => handleChange(identifier, e.toDate())}
                slotProps={{
                    textField: {
                     onClick: () => setOpen(true),
                     variant: "standard",             
                    },
                  }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker