import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import gql from 'graphql-tag'
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import { Transition, TransitionGroup, CSSTransition } from 'react-transition-group';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

//returns a new array that contains all the elements of array `a` that are not present in array `b`.
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
//returns a new array that contains all the elements that are present in both array `a` and array `b`.
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

//returns true if both arrays contain the same elements, regardless of their order.
function arraysEqual(a, b) {
    if (a.length !== b.length) return false;
    const sortedA = [...a].sort();
    const sortedB = [...b].sort();
    return sortedA.every((val, index) => val === sortedB[index]);
}

function getIdsFromNames(names, projects) {
    return names.map(name => {
        const project = projects.find(project => project.name === name);
        return project ? project.id : null;
    }).filter(id => id !== null);
}


const ProjectSelection = ({
    userId
}) => {
    const buttonRef = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const [checked, setChecked] = useState([]);

    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [showButton, setShowButton] = useState(false);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked).sort());
        setLeft(not(left, leftChecked).sort());
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked).sort());
        setRight(not(right, rightChecked).sort());
        setChecked(not(checked, rightChecked));
    };

    const customList = (items) => (
        <Paper sx={{
            width: 400,
            overflow: 'auto',
            height: 400, backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900]
        }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    const { data, loading, error, refetch } = useQuery(gql`
        query AllProjectsByUser($userId: ID) {
            allProjectsByUser(userId: $userId) {
                id
                name
                active
            }
        }
        `, { variables: { userId } })

    const [updateLinkedProjects, { data: mutationData, loading: loadingData, error: errorData }] = useMutation(gql`
        mutation UpdateLinkedProjects($projects: [ID], $userId: ID) {
            updateLinkedProjects(projects: $projects, userId: $userId) {
                id
            }
        }
        `)

    useEffect(() => {
        if (data) {
            const inactiveProjects = data.allProjectsByUser.filter(project => !(project.active))
                .map(project => project.name)
            setLeft(inactiveProjects)
            const activeProjects = data.allProjectsByUser.filter(project => project.active).map(project => project.name)
            setRight(activeProjects)
        }
    }, [data])

    // show button if projects are moved
    useEffect(() => {
        if (data?.allProjectsByUser && right.length > 0) {
            const activeProjectNames = data.allProjectsByUser
                .filter(project => project.active)
                .map(project => project.name);
            const differentProjects = !arraysEqual(activeProjectNames, right);
            setShowButton(differentProjects);
        }
    }, [data, right]);

    useEffect(() => {
        refetch();
    }, [userId])

    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt:5 }}>
            <CircularProgress />
        </Box>
    )

    const handleSave = async () => {
        const projectIds = getIdsFromNames(right, data.allProjectsByUser);
        await updateLinkedProjects({ variables: { projects: projectIds, userId } });
        refetch();
        enqueueSnackbar('Projects updated successfully', { variant: 'success' })
    }

    return (

            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mt:2}}>
                <Stack direction="column" spacing={2} alignItems="left">
                    <Typography variant="subtitle1">
                        Unassigned projects
                    </Typography>
                    <Grid item>{customList(left)}</Grid>
                </Stack>
                <Grid item>
                    <Grid container direction="column" alignItems="center">

                        <Button
                            sx={{
                                my: 0.5, backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{
                                my: 0.5, backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                            }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>

                    </Grid>
                </Grid>
                <Grid item>
                    <Stack direction="column" spacing={2} alignItems="left">
                        <Typography variant="subtitle1">
                            Assigned projects
                        </Typography>
                        {customList(right)}
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Transition
                        in={showButton}
                        timeout={0}
                        nodeRef={buttonRef}
                    >
                        {(state) => (
                            <div ref={buttonRef}>
                                <Button
                                    variant="contained"
                                    onClick={handleSave}
                                    style={{
                                        ...transitionStyles[state],
                                        transition: 'opacity 500ms ease-in-out',
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </Transition>

                </Grid>
            </Grid>
    )
}

export default ProjectSelection