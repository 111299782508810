import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


const DeleteConfirmationModal = ({
    show,
    handleCloseModal,
    handleDelete,
    title,
    body,
}) => {

    return (
        <Dialog open={show} onClose={handleCloseModal} fullWidth>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }} color='primary'>{title}</DialogTitle>
            <DialogContent>
                    {body}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleDelete} color="error">Delete</Button>
            </DialogActions>
        </Dialog>
    )

}

export default DeleteConfirmationModal;