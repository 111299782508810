import React, { useState } from 'react';
import { useCurrentUser } from '../../utils/CurrentUserContext';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
// import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CustomDatePicker from '../../utils/CustomDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { backendUrl } from '../../Settings';
import { QUERIES } from './config/queries'
import gql from 'graphql-tag';


const ExportRecords = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { data, loading, error } = useQuery(QUERIES.USERS)
    const [range, setRange] = useState({
        start: moment().subtract(30, 'days'),
        end: moment(),
    })
    const [exportInProgress, setExportInProgress] = useState(false);
    const [userId, setUserId] = useState(null)
    const [formErrors, setFormErrors] = useState({ show: false, errors: [] })
    const [ExportRecords, { data: exportData, loading: mutationLoading, error: mutationError }] = useMutation(gql`
    mutation ExportRecords($start: DateTime!, $end: DateTime!, $userId: ID!){
        exportRecords(start: $start, end: $end, userId: $userId){
            success,
            error,
            downloadUrl,
        }
    }`)


    if (loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            <CircularProgress />
        </Box>
    )
    if (error) return <p>Error :{error}</p>

    const handleExport = async () => {
        let errors = []
        if (!range.start || !range.end) {
            errors.push('Please select start and end date')
        }
        if (range.start > range.end) {
            errors.push('Start date cannot be greater than end date')
        }
        if (!userId && userId !== 0) {
            errors.push('Please select a user')
        }
        if (errors.length > 0) {
            setFormErrors({
                show: true,
                errors: errors,

            })
            return
        }
        
        setExportInProgress(true);
        const response = await ExportRecords({
            variables: {
                start: range.start,
                end: range.end,
                userId: userId,
            }
        })
        if (response.data.exportRecords.error) {
            setExportInProgress(false);
            return response.data.exportRecords.error
        }
        if (response.data.exportRecords.success) {
            const newTab = window.open(`${backendUrl}/${response.data.exportRecords.downloadUrl}`, '_blank');
            if (newTab) {
                newTab.open();
                enqueueSnackbar('Exported successfully', { variant: 'success' })
            } else {
                enqueueSnackbar('Unable to open new tab. Please disable your popup blocker and try again.', { variant: 'error' })
            }
            setExportInProgress(false);
        }
    }

    const handleChangeRange = (identifier, date) => {
        setRange({
            ...range,
            [identifier]: moment(date),
        })
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ flexGrow: 1, mt: 8 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" component="h2" gutterBottom>
                                Export Records
                            </Typography>
                            <Stack spacing={2} sx={{ mb: 5, mt: 5 }}>
                                <CustomDatePicker
                                    label="Start"
                                    defaultValue={range.start}
                                    handleChange={handleChangeRange}
                                    identifier="start"
                                    maxDate={range.end}
                                />
                                <CustomDatePicker
                                    label="End"
                                    defaultValue={range.end}
                                    handleChange={handleChangeRange}
                                    identifier="end"
                                    minDate={range.start}
                                />
                                <Autocomplete
                                    autoHighlight
                                    id="autocomplete"
                                    options={[{ id: 0, firstName: "All", lastName: "users" }, ...data.bigUsers]}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.lastName ? `${option.firstName} ${option.lastName}` : option.email}
                                    // sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="User" variant="standard" />}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setUserId(newValue.id)
                                        }
                                    }}
                                />
                                {formErrors.show &&
                                    <Box sx={{ mt: 3 }}>
                                        {formErrors.errors.map((error, index) => (
                                            <Alert key={index} sx={{ m: 1 }} severity="error">{error}</Alert>
                                        ))}
                                    </Box>
                                }
                            </Stack>
                            <Button variant="contained" color="primary" onClick={handleExport}>
                                {exportInProgress ? <CircularProgress sx={{ color: "#fff" }}/> : "Export"}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default ExportRecords