import gql from 'graphql-tag';


export const QUERIES = {
  HELPDESK_TICKETS_BY_USER: gql`
  query HelpdeskTicketsByContextUser{
    helpdeskTicketsByUser{
      id
      title
      user{
        id
        email
      }
      state
      urgency
      openDatetime
      closeDatetime
      description
      interventionStartDatetime
      interventionReport
      technician {
        email
        firstName
      }
    }
  }
  `,

  HELPDESK_TICKETS: gql`
    query HelpdeskTickets{
      helpdeskTickets{
        id
        title
        state
        description
        urgency
        openDatetime
        closeDatetime
        interventionStartDatetime
        interventionReport
        user{
          id
          email
          firstName
          lastName
        }
        helpdeskcommentSet {
          id
          technician {
            firstName
            email
          }
          comment
          creationDate
        }
        technician {
          email
          firstName
        }
      }
    }
  `,

  HELPDESK_TICKET: gql`
    query HelpdeskTicket($ticketId: ID!){
      helpdeskTicket(ticketId: $ticketId){
        id
        title
        state
        description
        urgency
        openDatetime
        closeDatetime
        interventionStartDatetime
        interventionReport
        screenshot
        user{
          id
          email
          firstName
          lastName
        }
        helpdeskcommentSet {
          id
          technician {
            firstName
            lastName
            email
          }
          comment
          creationDate
        }
        technician {
          email
          firstName
        }
      }
    }
  `,

//   HELPDESK_CATRGORIES: gql`
//     query HelpdeskCategories{
//       helpdeskCategories{
//         id
//         name
//         color
//       }
//     }
//   `
}




export const UPDATE_HELPDESK_TICKET = gql`
  mutation helpdeskTicketMutation(
    $ticketId: ID!,
    $state: String,
    $closeDatetime: DateTime,
    $interventionStartDatetime: DateTime,
    $interventionEndDatetime: DateTime,
    $interventionReport: String,
    $technician: ID,
    ){
      updateHelpdeskTicket(
        ticketId: $ticketId,
        state: $state,
        closeDatetime: $closeDatetime,
        interventionStartDatetime: $interventionStartDatetime,
        interventionEndDatetime: $interventionEndDatetime,
        interventionReport: $interventionReport,
        technician: $technician,
      ){
        id
      }
    }
  `
export const CREATE_TICKET = gql`
    mutation ($title:String!, $description:String!, $urgency:String!, $screenshot:Upload){
      createHelpdeskTicket(title:$title, description:$description, urgency:$urgency, screenshot:$screenshot){
        id
      }
    }
  `

export const CREATE_TICKET_COMMENT = gql`
    mutation ($comment:String!, $ticketId:ID!){
      createHelpdeskComment(comment:$comment, ticketId:$ticketId){
        ticket {
          id
        }
        comment
        technician {
            email
        }
        creationDate
      }
    }
`

export const UPLOAD_SCREENSHOT = gql`
    mutation ($screenshot:Upload!, $ticketId:ID!){
      uploadScreenshot(screenshot:$screenshot, ticketId:$ticketId){
        id
      }
    }
`
