
import { useState } from "react";
import Chip from '@mui/material/Chip';

export const TicketState = ({ state }) => {
    const [variant, setVariant] = useState("outlined");

    return (
        <Chip
            label={state}
            color={state === "Open" || state === "Processing" ? "primary"
                : state === "On Hold" ? "warning"
                    : "success"
            }
            size="small"
            variant={variant}
            onMouseEnter={() => setVariant("filled")}
            onMouseLeave={() => setVariant("outlined")}
        />
    )
};

