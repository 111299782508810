import React, { useState, useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Button from '@mui/material/Button';
// import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'notistack';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';

import CustomTimePicker from '../../utils/CustomTimePicker';
import { Transition, TransitionGroup, CSSTransition } from 'react-transition-group';
import gql from 'graphql-tag';

const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

const WorkingSchedule = ({
    userId
}) => {
    // This workaround is used to avoid console warnings https://github.com/reactjs/react-transition-group/issues/668
    const buttonRef = React.useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    const [showButton, setShowButton] = useState(false)
    const [dayChecked, setDayChecked] = useState({
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
    })
    const [partTime, setPartTime] = useState({
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
    })
    const [workingSchedule, setWorkingSchedule] = useState(null)
    const { data, loading: _loading } = useQuery(gql`
        query userProfile($userId: ID) {
            userProfile(userId: $userId) {
                id
                workSchedule
            }
        }`,
        {
            variables: {
                userId
            }
        })

    const [updateUserProfile, { data: mutationData, loading, error }] = useMutation(gql`
    mutation UpdateUserProfile($workSchedule: JSONString, $userId: ID!){
        updateUserProfile(workSchedule: $workSchedule, userId: $userId){
            id,
            workSchedule
        }
    }`)

    // This will populate the checkboxes for part time and working days
    useEffect(() => {
        if (data?.userProfile.workSchedule) {
            setWorkingSchedule(data.userProfile.workSchedule)

            const newDayChecked = days.reduce((acc, day) => {
                acc[day] = Object.keys(data.userProfile.workSchedule[day] || {}).length !== 0;
                return acc;
            }, {});
            setDayChecked(newDayChecked)

            const newPartTime = days.reduce((acc, day) => {
                acc[day] = data.userProfile.workSchedule[day].break_start == '';
                return acc;
            }, {});
            setPartTime(newPartTime)
        }
    }, [data?.userProfile.workSchedule])

    // This will show the save button if the user has made changes to the working schedule
    useEffect(() => {
        if (data?.userProfile.workSchedule && workingSchedule) {
            setShowButton(JSON.stringify(workingSchedule) != JSON.stringify(data.userProfile.workSchedule))
        }
    }, [workingSchedule])

    if (loading || _loading) return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    )

    const handleDayCheck = (event, day) => {
        setDayChecked({ ...dayChecked, [day]: event.target.checked })
        setWorkingSchedule({
            ...workingSchedule,
            [day]: event.target.checked
                ? { start_time: '09:00', end_time: '16:36' }
                : {}
        });
    }

    // TODO: Save the working schedule to the database
    const handleSaveSchedule = async () => {
        await updateUserProfile({
            variables: {
                workSchedule: JSON.stringify(workingSchedule),
                userId
            }
        })
        // setShowButton(false)
        enqueueSnackbar('Working schedule updated successfully', { variant: 'success' })

    }

    const getWorkingHours = (start, end, break_start, break_end) => {
        const startTime = moment(start, "HH:mm");
        const endTime = moment(end, "HH:mm");
        const breakStart = moment(break_start, "HH:mm");
        const breakEnd = moment(break_end, "HH:mm");
        const breakDuration = moment.duration(breakEnd.diff(breakStart));
        const duration = moment.duration(endTime.diff(startTime)).subtract(breakDuration);

        const hours = duration.hours();
        const minutes = duration.minutes();

        return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`

    }

    return (

        <Grid container spacing={1}>
            <Grid item xs={12} sx={{ mt: 3 }}>
                <Stack spacing={2} direction="row" justifyContent="center">
                    {workingSchedule ?
                        days.map((day, index) => (
                            <FormControlLabel
                                key={index}
                                control={<Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                    checked={dayChecked[day]}
                                    onChange={(event) => handleDayCheck(event, day)}
                                />} label={day} />
                        ))
                        : <CircularProgress />}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} direction="column" sx={{ mt: 1 }} alignItems="center">
                    <TransitionGroup>
                        {days.filter((day) => dayChecked[day]).map((day, index) => {
                            const nodeRef = React.createRef();
                            return (
                                <CSSTransition
                                    key={day}
                                    timeout={500}
                                    classNames="item"
                                    style={{ marginBottom: '20px' }}
                                    nodeRef={nodeRef}
                                >
                                    <div ref={nodeRef}>
                                        <Stack key={index} spacing={2} direction="row" alignItems="center">
                                            <FormControlLabel
                                                control={<Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                                    checked={partTime[day]}
                                                    onChange={(event) => {
                                                        setPartTime({ ...partTime, [day]: event.target.checked })
                                                        setWorkingSchedule({
                                                            ...workingSchedule,
                                                            [day]: event.target.checked
                                                                ? { ...workingSchedule[day], break_start: '', break_end: '' }
                                                                : { ...workingSchedule[day], break_start: '12:00', break_end: '13:00' }
                                                        })
                                                    }
                                                    }
                                                />}
                                                label="Part Time"
                                                labelPlacement="start"
                                            />
                                            <Divider orientation="vertical" flexItem />
                                            <Typography variant="body1" component="p" sx={{ width: '50px' }}>
                                                {day}
                                            </Typography>
                                            <Box sx={{ width: '130px' }}>
                                                <CustomTimePicker
                                                    label="Day Start"
                                                    value={workingSchedule[day].start_time ? moment(workingSchedule[day].start_time, 'HH:mm') : undefined}
                                                    handleChange={(value) => {
                                                        setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], start_time: value.format('HH:mm') } })
                                                    }}
                                                    maxTime={partTime[day] ?
                                                        moment(workingSchedule[day].end_time, 'HH:mm').subtract(1, 'hours') :
                                                        (workingSchedule[day].break_start ?
                                                            moment(workingSchedule[day].break_start, 'HH:mm').subtract(1, 'hours') :
                                                            undefined)}
                                                    minTime={moment('05:00', 'HH:mm')
                                                    }
                                                />
                                            </Box>
                                            <Typography variant="body1">
                                                to
                                            </Typography>
                                            {partTime[day] ? null : <>
                                                <Box sx={{ width: '130px' }}>
                                                    <CustomTimePicker
                                                        label="Break Start"
                                                        value={workingSchedule[day].break_start ? moment(workingSchedule[day].break_start, 'HH:mm') :
                                                            setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], break_start: '12:00' } })}
                                                        handleChange={(value) => {
                                                            setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], break_start: value.format('HH:mm') } })
                                                        }}
                                                        minTime={moment('11:00', 'HH:mm')}
                                                        maxTime={workingSchedule[day].break_end ?
                                                            moment(workingSchedule[day].break_end, 'HH:mm').subtract(15, 'minutes') :
                                                            moment(workingSchedule[day].end_time, 'HH:mm').subtract(1, 'hours')}

                                                    />
                                                </Box>
                                                <Typography variant="body1">
                                                    to
                                                </Typography>
                                                <Box sx={{ width: '130px' }}>

                                                    <CustomTimePicker
                                                        label="Break End"
                                                        value={workingSchedule[day].break_end ? moment(workingSchedule[day].break_end, 'HH:mm') :
                                                            setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], break_end: '13:00' } })
                                                        }
                                                        handleChange={(value) => {
                                                            setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], break_end: value.format('HH:mm') } })
                                                        }}
                                                        minTime={workingSchedule[day].break_start ?
                                                            moment(workingSchedule[day].break_start, 'HH:mm').add(15, 'minutes') :
                                                            moment(workingSchedule[day].start_time, 'HH:mm').add(1, 'hours')
                                                        }
                                                        maxTime={workingSchedule[day].end_time ?
                                                            moment(workingSchedule[day].end_time, 'HH:mm').subtract(1, 'hours') :
                                                            undefined
                                                        }

                                                    />
                                                </Box>
                                                <Typography variant="body1">
                                                    to
                                                </Typography>
                                            </>
                                            }
                                            <Box sx={{ width: '130px' }}>

                                                <CustomTimePicker
                                                    label="Day End"
                                                    value={workingSchedule[day].end_time ? moment(workingSchedule[day].end_time, 'HH:mm') : undefined}
                                                    handleChange={(value) => {
                                                        setWorkingSchedule({ ...workingSchedule, [day]: { ...workingSchedule[day], end_time: value.format('HH:mm') } })
                                                    }}
                                                    minTime={partTime[day] ?
                                                        moment(workingSchedule[day].start_time, 'HH:mm').add(1, 'hours') :
                                                        workingSchedule[day].break_end ?
                                                            moment(workingSchedule[day].break_end, 'HH:mm').add(1, 'hours') :
                                                            undefined
                                                    }
                                                    maxTime={moment('20:00', 'HH:mm')}
                                                />
                                            </Box>
                                            <Typography variant="body1">
                                                {getWorkingHours(
                                                    workingSchedule[day].start_time,
                                                    workingSchedule[day].end_time,
                                                    workingSchedule[day].break_start,
                                                    workingSchedule[day].break_end
                                                )}
                                            </Typography>
                                        </Stack>
                                    </div>
                                </CSSTransition>
                            )
                        })}
                    </TransitionGroup>
                </Stack>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                {/* {showButton &&
                                    <Button variant="contained" onClick={() => console.log(workingSchedule)}>
                                        Save
                                    </Button>
                                } */}
                <Transition
                    in={showButton}
                    timeout={0}
                    nodeRef={buttonRef}
                >
                    {(state) => (
                        <div ref={buttonRef}>
                            <Button
                                variant="contained"
                                onClick={handleSaveSchedule}
                                style={{
                                    ...transitionStyles[state],
                                    transition: 'opacity 500ms ease-in-out',
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </Transition>

            </Grid>
        </Grid>

    )
}

export default WorkingSchedule