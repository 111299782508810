import gql from 'graphql-tag';

export const QUERIES = {
  USERS: gql`
    query MyQuery {
      bigUsers {
        email
        firstName
        lastName
        id
      }
    }
  `,

  CREATE_RECORD: gql`
  mutation createRecordMutation($projectId:ID!, $start:DateTime!, $end:DateTime!, $description:String, $userId:ID){
      createRecord(
        projectId:$projectId,
        start:$start,
        end:$end,
        description:$description,
        userId:$userId
      ){
        id
        start
        end
        project {
          name
          id
        }
        user {
          email
          id
        }
      }
    }
  `,
  EDIT_RECORD: gql`
  mutation editRecordMutation($id:ID!, $projectId:ID!, $start:DateTime!, $end:DateTime!, $description:String, $state:String){
      editRecord(
        id:$id,
        projectId:$projectId,
        start:$start,
        end:$end,
        description:$description,
        state:$state
      ){
        id
        start
        end
        state
        project {
          name
          id
        }
        user {
          email
          id
        }
      }
    }
  `,
  MY_RECORDS: gql`
    query MyRecords ($start:String, $end:String, $allRecords:Boolean, $userId:ID){
      recordsByUser (start:$start, end:$end, allRecords:$allRecords, userId:$userId) {
        start
        end
        description
        state
        id
        user {
          username
          id
        }
        project {
          name
          id
          category {
            name
            color
            id
          }
        }
      }
    }  

    `,
  PROJECTS: gql`
  query Projects{
    projects{
      id
      name
      category{
        name
        color
        id
      }
    }
  }
  `,
  MY_LINKED_PROJECTS: gql`
    query MyProjectsQuery ( $userId:ID! ) {
        linkedProjectsByUser ( userId:$userId ) {
            project {
              id
              name
            }
        }
    }
  `,
  ALL_PROJECTS_BY_USER: gql`
  query allPrQuery($userId:ID){
    allProjectsByUser(userId:$userId){
      name
      id
      active
    }
  }
  `,
  LINK_PROJECT: gql`
  mutation LinkProject($projectId:ID!){
    linkProjectToUser(
        projectId:$projectId,
      ){
        project {
          name
          id
        }
        user {
          email
          id
        }
      }
    }
  `,

  DOUGHNUT_GRAPH: gql`
  query doughnutGraph($start: String!, $end: String!, $userId:ID){
    doughnutGraph(start:$start, end:$end, userId:$userId){
      label
      hours
    }
  }
  `
}

