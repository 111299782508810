import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Varela Round',
      'sans-serif',
    ].join(','),
  },

  palette: {
    text: {
      primary: '#454951',
    },
    primary: {
      main: '#E975AA',
      contrastText: '#fff',
    },
    secondary: {
      main: "#D3348B",
    },
    low: {
      main: '#8AC6D0', // Light blue
    },
    medium: {
      main: '#6CB28F', // Light green
    },
    high: {
      main: '#FFD464', // Bright yellow
    },
    critical: {
      main: '#FF6B6B', // Bright red
    },
  }
},
  // Other theme configurations here
);

export default theme;
