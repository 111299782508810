import moment from 'moment';
import 'moment/locale/en-gb';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function CustomDateTimePicker(props) {
    const { label, defaultValue, handleChange, identifier } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateTimePicker
                // add default value
                defaultValue={moment(defaultValue)} 
                value={moment(defaultValue)}
                slotProps={{ textField: { variant: "standard" } }}
                label={label}
                ampm={false}
                onChange={(e) => handleChange(identifier, e.toDate())}
            
            />
        </LocalizationProvider>
    );
}

export default CustomDateTimePicker;