import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  tabs,
  content,
  data,
  setSelectedUser,
  selectedUser,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexGrow: 1, borderBottom: 1, borderColor: 'divider', pr: 2 }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        <Autocomplete
          autoHighlight
          id="autocomplete"
          options={data.users}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.lastName ? `${option.firstName} ${option.lastName}` : option.email}
          renderInput={(params) => (
            <TextField
              {...params}
              label="User"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedUser(newValue.id)
            }
          }}
          onInputChange={(event, newInputValue) => {
            if (!newInputValue) {
              setSelectedUser(null);
            }
          }}
          sx={{ width: 300 }} // Adjust this value as needed
        />
      </Box>
      {content.map((item, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {selectedUser ? <>{item} </> : <>
            Please select a user
          </>}

        </CustomTabPanel>
      ))}
    </Box>
  );
}