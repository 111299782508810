import React from 'react';
import { from, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import CustomDateTimePicker from '../../utils/CustomDateTimePicker';
import CustomDatePicker from '../../utils/CustomDatePicker';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FastForwardIcon from '@mui/icons-material/FastForward';
import IconButton from '@mui/material/IconButton';
import { minutesToHoursMinutes } from '../../utils/config';
import { QUERIES } from './config/queries'
import moment from 'moment';
import { getWeekDay } from '../../utils/config';

const RecordModal = ({
    show,
    handleCloseModal,
    onSave,
    onDelete,
    title,
    record,
    handleChange,
    linkedProjects,
    recordErrors,
    dailyMinutesWorked,
    expectedWorkingMinutes,
    fromHR,
    selectedDayRecords,
    workingSchedule,
}) => {
    // const [open, setOpen] = useState(false);
    const myProjects = linkedProjects.filter((x) => (x.active))
    
    const handleAutoFillDay = () => {
        // If no other records on the day, skip
        if (selectedDayRecords.length === 0) {
            return
        }
        // If part time day, ignore break
        if (workingSchedule[getWeekDay(record.start)].break_start == "") {
            handleChange("end", moment(record.start).add(expectedWorkingMinutes - dailyMinutesWorked, 'minutes').toDate())
            return
        }

        // Get break start and break end into date time objects
        const [break_start_hours, break_start_minutes] = workingSchedule[getWeekDay(record.start)].break_start.split(':')
        const break_start = moment(record.start).set({ hour: break_start_hours, minute: break_start_minutes })
        const [break_end_hours, break_end_minutes] = workingSchedule[getWeekDay(record.start)].break_end.split(':')
        const break_end = moment(record.start).set({ hour: break_end_hours, minute: break_end_minutes })

        // If record starts after break, ignore break
        if (record.start > break_start) {
            handleChange("end", moment(record.start).add(expectedWorkingMinutes - dailyMinutesWorked, 'minutes').toDate())
            return
        }
        
        // End time is remaining minutes + break time
        handleChange("end", moment(record.start).add((expectedWorkingMinutes - dailyMinutesWorked) + (break_end.diff(break_start, 'minutes')), 'minutes').toDate())
        handleChange("autoFill", true)
        
    }

    return (
        <Dialog open={show} onClose={handleCloseModal} fullWidth>
            <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }} color='primary'>{title}</DialogTitle>
            <DialogContent>
                <Divider />

                <FormControl fullWidth sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                        {record.allDay ?
                            <>
                                <Typography variant="body2" color="text.secondary">
                                    This will autofill the whole day based on your working schedule.
                                    <br />
                                    If you select a different end date it will autofill multiple days.
                                </Typography>
                                {/* <Typography variant="body2" color="text.secondary">
                                    This will autofill the whole day based on your working schedule. 
                                    If you select a different end date it will autofill multiple days.
                                </Typography> */}
                                <CustomDatePicker
                                    label="Start"
                                    identifier="start"
                                    defaultValue={record.start}
                                    handleChange={handleChange}
                                />
                                <CustomDatePicker
                                    label="End"
                                    identifier="end"
                                    defaultValue={record.end}
                                    handleChange={handleChange}
                                />
                            </> :
                            <>
                                <CustomDateTimePicker
                                    label="Start"
                                    identifier="start"
                                    defaultValue={record.start}
                                    handleChange={handleChange}
                                />
                                <CustomDateTimePicker
                                    label="End"
                                    identifier="end"
                                    defaultValue={record.end}
                                    handleChange={handleChange}
                                />
                                {typeof expectedWorkingMinutes === 'number' &&
                                    typeof dailyMinutesWorked === 'number' &&
                                    dailyMinutesWorked < expectedWorkingMinutes &&
                                    !fromHR &&
                                    selectedDayRecords.length !== 0 &&
                                    record.newRecord &&
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Typography variant="body2" color="text.secondary">

                                            Complete day: {minutesToHoursMinutes(expectedWorkingMinutes - dailyMinutesWorked)} left today

                                        </Typography>

                                        <IconButton
                                            color="primary"
                                            sx={{ border: '2px solid', borderColor: 'primary' }}
                                            onClick={handleAutoFillDay}
                                        >
                                            <FastForwardIcon fontSize="small" />
                                        </IconButton>

                                    </Stack>
                                }
                            </>
                        }

                        <Autocomplete
                            autoHighlight
                            id="autocomplete"
                            defaultValue={(record.newRecord) ? null : myProjects.find(x => x.id === record.project.id)}
                            options={myProjects}
                            getOptionLabel={(option) => option.name}
                            // sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Project" variant="standard" autoFocus />}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    const { name, id } = newValue
                                    handleChange("project", { name, id })
                                }
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="Description"
                            type="text"
                            fullWidth
                            value={record.description}
                            variant="standard"
                            onChange={(e) => handleChange("description", e.target.value)}
                        />
                    </Stack>
                </FormControl>
                {recordErrors.show &&
                    <Box sx={{ mt: 3 }}>

                        {Object.entries(recordErrors).map((error) => {
                            if (error[1] !== "" && error[1] !== true) {
                                return (
                                    <Alert sx={{ m: 1 }} severity="error">{error[1]}</Alert>
                                )
                            }
                        })}

                    </Box>
                }
            </DialogContent>
            <DialogActions>
                {/*Pull delete button left */}
                {!record.newRecord && (
                    <Button
                        onClick={onDelete}
                        color="error"
                        sx={{
                            mr: 'auto',
                            ml: 1,
                        }}
                    >
                        Delete
                    </Button>
                )}
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>

    );
}

export default RecordModal;