import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import LoginScreen from './LoginScreen'
import { Settings } from '../Settings'
import { Outlet } from 'react-router-dom'

const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation ( $token: String! ) {
    refreshToken ( token: $token ) {
      token
    }
  }
`;

const AuthenticationRequired = () => {

    const [verifyToken] = useMutation(gql`
        mutation VerifyToken ( $token: String! ) {
          verifyToken(token: $token) {
            payload
          }
        }
      `)
    const [refreshAccessToken] = useMutation(REFRESH_TOKEN_MUTATION)
  
    const [showLoginForm, setShowLoginForm] = useState(true)
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
      const interval = setInterval(() => {
        (async function loop() {
          const accessTokenValue = localStorage.getItem(Settings.ACCESS_TOKEN)
          if (!accessTokenValue) return;
          let refreshResponse
          try {
            refreshResponse = await refreshAccessToken({ variables: { token: accessTokenValue } })
            localStorage.setItem(Settings.ACCESS_TOKEN, refreshResponse.data.refreshToken.token)

          } catch (e) {
            localStorage.clear()
            setShowLoginForm(true)
            setInitialLoading(false)
          }
        })()
      }, 1000 * Settings.ACCESS_TOKEN_REFRESH_IN_SECONDS) 
      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      const token = localStorage.getItem(Settings.ACCESS_TOKEN)

      if (token) {

        (async function useEffectInner() {
          let verifyResponse
          try {
            verifyResponse = await verifyToken({ variables: { token } })
          } catch (e) {
            setShowLoginForm(true)
            setInitialLoading(false)
            localStorage.clear()
            return
          }
          const { errors } = verifyResponse
          if (errors && errors.length > 0) {
            setShowLoginForm(true)
            setInitialLoading(false)
            localStorage.clear()
            return
          }
          const refreshResponse = await refreshAccessToken({ variables: { token } })
          if (refreshResponse.errors) {
            throw new Error(refreshResponse.errors)
          }
          localStorage.setItem(Settings.ACCESS_TOKEN, refreshResponse.data.refreshToken.token)

          setShowLoginForm(false)
          setInitialLoading(false)
        })()
      } else {
        setShowLoginForm(true)
        setInitialLoading(false)
      }
    }, [])
    if (initialLoading) return "..."

    if (!showLoginForm) {
        return <Outlet />
    } 
    return <LoginScreen />
  }

export default AuthenticationRequired;