import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useState } from "react";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment'
import { QUERIES } from './config/queries';
import { useTheme } from '@mui/material/styles';

import { TicketState } from './config/config';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// function CustomToolbar({ filterModel, setFilterModel, setChecked, checked }) {
//     const handleCheckboxChange = (event) => {
//         setChecked(event.target.checked);
//         const items = filterModel.items.map((item) => {
//             if (item.field === "state") {
//                 return {
//                     ...item,
//                     value: event.target.checked ? "Open" : "",
//                 };
//             }
//             return item;
//         });
//         setFilterModel({
//             items,
//         });
//     }
//     const handleSearch = (event) => {
//         const value = event.target.value || "";
//         const items = filterModel.items.map((item) => {
//             if (item.field === "*") {
//                 return {
//                     ...item,
//                     value,
//                 };
//             }
//             return item;
//         });
//         setFilterModel({
//             items,
//         });
//     };

// return (
//     <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
//         <FormControlLabel
//             control={<Checkbox checked={checked} onChange={handleCheckboxChange} />}
//             label="Show only open tickets"
//         />
//         <GridToolbarQuickFilter sx={{ ml: 5 }} onChange={handleSearch} value={filterModel.items[1].value} />
//     </GridToolbarContainer>
// );
//     }

const AdminHelpdesk = () => {
    // const [filterModel, setFilterModel] = useState({
    //     items: [
    //         { field: 'state', operator: 'equals', value: "", id: 1 },
    //         { field: '*', operator: 'equals', value: "" , id: 2}
    //     ]
    // });
    const [checked, setChecked] = useState(false);
    const [rows, setRows] = useState([]);

    const theme = useTheme();
    const columns = [
        {
            field: "urgency", headerName: "Urgency",
            renderCell: (params) => (
                <Typography variant="body1" color={params.row.urgency === "Low" ? theme.palette.low.main :
                    params.row.urgency === "Medium" ? theme.palette.medium.main :
                        params.row.urgency === "High" ? theme.palette.high.main :
                            theme.palette.critical.main}>
                    {params.row.urgency}
                </Typography>
            )
        },
        { field: "user", headerName: "User", valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName.charAt(0)}.` },
        { field: "title", sortable: false, flex: 1, headerName: "Title" },
        {
            field: "state",
            width: 150,
            headerName: "Status",
            renderCell: (params) => (
                <TicketState state={params.row.state} />
            )
        },

        {
            field: "openDatetime",
            flex: 1,
            headerName: "Open Date",
            type: "date",
            valueFormatter: (params) =>
                params?.value ?
                    moment(params.value).format('DD/MM/YYYY') : "",

        },
        {
            field: "closeDatetime",
            flex: 1,
            headerName: "Closed Date",
            type: "date",
            valueFormatter: (params) =>
                params?.value ?
                    moment(params.value).format('DD/MM/YYYY') : "N/A",

        },
        { field: "technician", flex: 1, headerName: "Technician", valueGetter: (params) => params.row.technician ? params.row.technician.firstName : "Not assigned yet" },
        {
            field: "actions", sortable: false, headerName: "", renderCell: (params) => (
                <Link
                    to={`/ticket/${params.row.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <LaunchIcon
                        sx={{ color: theme.palette.primary.main }}
                    />
                </Link>
            )
        }
    ];

    const location = useLocation();
    const { loading, error, data, refetch } = useQuery(QUERIES.HELPDESK_TICKETS, {
        onCompleted: (data) => {
            setRows(data.helpdeskTickets);
        }
    });

    useEffect(() => {
        refetch()
    }, [location.pathname])

    if (error) return `Error! ${error.message}`;

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    display: 'flex',
                    // this selector is used to apply styles to all direct children of the parent element that are not `style` elements.
                    '& > :not(style)': {
                        margin: 'auto',
                        width: '100%',
                        p: 3,
                        mt: 5,
                    },
                }}

            >
                <Paper>

                    <Grid
                        container
                        spacing={1}
                        sx={{
                            '@media (max-width: 900px)': {
                                flexDirection: 'column',
                            },
                        }}
                    >
                        <Grid item md={12}>
                            <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>Tickets</Typography>
                        </Grid>
                        <Grid item md={12} sx={{ mb: 2 }}>

                            <DataGrid
                                loading={loading}
                                rows={rows}
                                columns={columns}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: { disableToolbarButton: true },
                                        csvOptions: { disableToolbarButton: true },
                                    },
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 20,
                                        },
                                    },
                                    sorting: {
                                        sortModel: [
                                            { field: 'state', sort: 'desc' },
                                        ],
                                        // Custom sort comparator function
                                        comparator: (v1, v2) => {
                                            const stateOrder = { Open: 1, 'On Hold': 2, Closed: 3 };
                                            const state1 = v1?.toLowerCase();
                                            const state2 = v2?.toLowerCase();
                                            if (stateOrder[state1] < stateOrder[state2]) {
                                                return -1;
                                            }
                                            if (stateOrder[state1] > stateOrder[state2]) {
                                                return 1;
                                            }
                                            return 0;
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20]}
                                disableRowSelectionOnClick
                            />

                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    )
}

export default AdminHelpdesk