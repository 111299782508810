
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import WorkingSchedule from './WorkingSchedule';
import ProjectSelection from './ProjectSelection';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';



const UserSettings = () => {

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper sx={{
                        p: 2,
                        minHeight: '500px',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="h6" component="h2">
                            My Projects
                        </Typography>
                        <ProjectSelection />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <WorkingSchedule />
                </Grid>
            </Grid>
        </Container>
    )
}

export default UserSettings